<template>
  <div id="app">
    <!-- <img src="@/assets/images/fast-Intro.gif" v-if="renderGif" class="full-screen-image"> -->
    <video ref="videoRef" id="myvideo" class="full-screen-video" v-if="renderGif && !isMobile" autoplay muted playsinline>
      <source src="https://s3.ap-south-1.amazonaws.com/enrichr.public.images/pool-city/fast-Intro.mp4" type="video/mp4">
    </video>
    <MobileHome v-if="isMobile && renderGif && isGifLoading"/>

    <div id="loading-wrapper" v-if="!renderGif ? isLoading : ''">
      <div id="loading-text">LOADING</div>
      <div id="loading-content"></div>
    </div>
    <router-view/>
  </div>
</template>
<script>
export default {
  components:{
    MobileHome:()=>import('@/components/MobileHome.vue')
  },
  data() {
    return {
      renderGif: true
    }
  },
  computed: {
    isLoading(){
      return this.$store.getters.isLoading
    },
    isMobile(){
      return window && window.innerWidth <= 991;
    },
    isGifLoading(){
      return this.$store.getters.isGifLoading
    }
  },
  mounted(){
    this.$store.dispatch('setLoading', false);
    this.$store.dispatch('setIsGifLoading', true);
    if(this.$route.query.ENAUCP_ID){
      localStorage.setItem('enrichr-augment-p1-cp-id', this.$route.query.ENAUCP_ID)
    }
    if(!this.isMobile){
        this.$refs.videoRef.onended=()=>{
        this.renderGif = false;   
        this.$store.dispatch('setIsGifLoading', false);   
      }

    }
  }
}
</script>
<style scoped>
.mystyle {
  background-image: ();
}
.full-screen-image{
  height: 100vh;
  width: 100vw;
}
.full-screen-video{
  height: 100vh;
  width: 100vw;
  object-fit: fill;
  overflow-y: hidden;
  position: fixed;
  z-index: 1032;
}

@media (max-width:767px){
  .full-screen-video{
    object-fit: cover;
  }
}
</style>